import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./InfoModal.css";
// Assets
import axios from "axios";
import CloseButtonIcon from "../../../../assets/svg/CloseButtonIcon.svg";

interface InfoModalProps {
  handleClose: () => void;
  id?: number | null;
}
interface katastarData {
  id: number;
  nazivObveznika: string;
  sifraObveznika: string;
  oib: string | null;
  osoba: string;
  adresaObveznika: string;
  naselje: string;
  pttBroj: string;
  mjesto: string;
  drzava: string;
  sifraObjekta: string;
  nazivObjekta: string;
  naseljeObjekta: string;
  kcBroj: string;
  zona: string;
  povrsina: number;
  iznosKom: number;
  klasa: string;
  urBroj: string;
  datumIzdavanja: string;
  brojCestice: string;
  katastar: string;
  opisSektora: string;
  koefNamjene: number;
  koefZone: number;
}
export const InfoModalKomunalna: FunctionComponent<InfoModalProps> = ({
  id,
  handleClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [katastarData, setKatastarData] = useState<katastarData | undefined>(
    undefined
  );
  useEffect(() => {
    axios
      .get<katastarData>(
        `https://webgis.netscale.agency/komunalna-naknada/${id}`
      )
      .then((res) => setKatastarData(res.data))
      .catch((e) => console.log(e));
  }, [id]);

  return katastarData ? (
    <div className="modal-overlay">
      <div className="modal-container" ref={modalRef}>
        <div className="modal-top-bar">
          <img
            alt="close"
            src={CloseButtonIcon}
            className="close-icon"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        <p style={{ margin: "24px auto", fontWeight: 600, fontSize: 28 }}>
          Informacije o komunalnoj naknadi
        </p>
        <div className="modal-row-container">
          <div className="modal-row">
            <p>Klasa:</p>
            <p>{katastarData.klasa || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>KC broj:</p>
            <p>{katastarData.kcBroj || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>OIB Obveznika:</p>
            <p>{katastarData.oib || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Adresa Obveznika:</p>
            <p>{katastarData.adresaObveznika || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Datum Izdavanja:</p>
            <p>{katastarData.datumIzdavanja || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Katastarska Čestica:</p>
            <p>{katastarData.brojCestice || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Naselje Objekta:</p>
            <p>{katastarData.naseljeObjekta || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Naziv Objekta:</p>
            <p>{katastarData.nazivObjekta || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Naziv Obveznika:</p>
            <p>{katastarData.nazivObveznika || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Opis Sektora:</p>
            <p>{katastarData.opisSektora || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Osoba:</p>
            <p>{katastarData.osoba || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Površina:</p>
            <p>{katastarData.povrsina || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Ptt Broj:</p>
            <p>{katastarData.pttBroj || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Sifra Objekta:</p>
            <p>{katastarData.sifraObjekta || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Sifra Obveznika:</p>
            <p>{katastarData.sifraObveznika || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>UR Broj:</p>
            <p>{katastarData.urBroj || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Iznos Komunalne:</p>
            <p>{katastarData.iznosKom || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Zona:</p>
            <p>{katastarData.zona || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Naselje:</p>
            <p>{katastarData.naselje || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Mjesto:</p>
            <p>{katastarData.mjesto || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Država:</p>
            <p>{katastarData.drzava || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Katastar:</p>
            <p>{katastarData.katastar || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Koeficijent namjene:</p>
            <p>{katastarData.koefNamjene || "nema podatka"}</p>
          </div>
          <div className="modal-row">
            <p>Koeficijent zone:</p>
            <p>{katastarData.koefZone || "nema podatka"}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
