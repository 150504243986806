import { FunctionComponent } from "react";

export interface SpatialPlanLayer {
  name: string;
  color: string;
}

export interface SpacialPlanSublayerListProps {
  spatialPlansLayer: SpatialPlanLayer[];
}

export const SpacialPlanSublayerList: FunctionComponent<
  SpacialPlanSublayerListProps
> = ({ spatialPlansLayer }) => {
  return (
    <div className="pp-container">
      {spatialPlansLayer.map((item) => (
        <label key={item.color} className="label">
          {item.name}
          <div className="dot-mark" style={{ backgroundColor: item.color }} />
        </label>
      ))}
    </div>
  );
};
