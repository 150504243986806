import axios from "axios";
import { Ownership } from "../components/infoModal/infoModalInterfaces";

export interface Address {
  descriptiveAddress: string | null;
  streetName: string | null;
  streetOrdinalNumber: number | null;
  houseNumber?: string | null;
  houseNumberAlphabeticExtension?: string | null;
  houseNumberNumericExtension?: string | null;
  settlementName?: string | null;
  settlementNumber?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export interface Holder {
  id: number | null;
  ordinalNumber: number | null;
  legalRelationship: string | null;
  holderShareNumerator: number | null;
  holderShareDenominator: number;
  remark: string | null;
  shareOrdinalNumber: number | null;
  floorOrdinalNumber: number | null;
  shareNumerator: number | null;
  shareDenominator: number | null;
  name: string;
  taxNumber: string | null;
  citizenIdentificationNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  fatherName: string | null;
  maidenName: string | null;
  gender: string | null;
  titleDeedId: number | null;
  addresses: Address[];
}

export interface TitleDeed {
  id: number | null;
  municipality: string | null;
  number: string;
  holders: Holder[] | null;
  area: number | null;
  income: number | null;
  type: string;
  harmonizationStatus: string;
}

export interface Building {
  id: number;
  municipality: string | null;
  number: number;
  area: number;
  name: string;
  typeCode: number;
  typeName: string;
  titleDeedNumber: string;
  status: string;
  requiredDocumentation: string;
  constructionRight: string;
  addresses: Address[];
  documents: Document[];
}

export interface Document {
  buildingId: number;
  typeOfDocument: string;
  typeCode: string;
  bodyName: string;
  orderNumber: string;
  description: string;
  pageCount: string;
  date: string;
  class: string;
}

export interface LandUse {
  id: number;
  municipality: string | null;
  area: number;
  typeCode: number | null;
  typeName: string;
  titleDeed: TitleDeed;
  descriptiveAddress: string;
  titleDeedNumber: string;
  status: string;
  constructionRight: string;
}

export interface Identification {
  parcelId: number;
  lrParcelId: number;
  mainBookId: number;
  municipalityNumber: string;
  parcelNumber: string;
  lrParcelNumber: string;
  lrParcelSubnumber: string;
  linkLabel: string;
  lrLinkLabel: string;
  verified: string;
}

export interface OwnershipExtract {
  udio: string;
  vlasnik: string;
  adresa: string;
  oib: string;
}

export interface Extract {
  zK_broj: string | null;
  zK_ulozak: string | null;
  ukupna_povrsina: number;
  udio: OwnershipExtract[];
  zemljiste: { oznaka_zemljista: string; povrsina: number }[];
}

export interface CatastreData {
  number: string;
  municipality: string | null;
  descriptiveAddress: string | null;
  status: string | null;
  addresses: Address[];
  identifications: Identification[] | null;
  landUses: LandUse[];
  buildings: Building[];
  titleDeeds: TitleDeed[] | null;
  holders: Holder[] | null;
  extract: Extract;
  realArea: number;
}

export const getParcelInfos = async (ids?: string[]) => {
  return await axios.post<CatastreData[]>(
    `https://webgis.netscale.agency/data/cadastre`,
    ids
  );
};
export const getLandRegistries = async (ids?: string[]) => {
  return await axios.post<Ownership[]>(
    `https://webgis.netscale.agency/data/land-registry`,
    ids
  );
};
export const filterDuplicates = (data: Ownership[]) => {
  const uniqueIds = new Set<number>();
  return data.filter(
    (obj) => !uniqueIds.has(obj.id) && (uniqueIds.add(obj.id), true)
  );
};
