import "./SideButtons.css";
// Assets
import LayersIcon from "../../assets/svg/LayersIcon.svg";
import { useToolsState } from "../../context/ToolsContext";

const SideButtons = () => {
  const { layer, setLayer } = useToolsState();

  return (
    <>
      <div className="buttons-container">
        <button
          onClick={() => {
            setLayer(!layer);
          }}
          className={layer ? "button-box" : "button-box focus"}
        >
          <img alt="layers" src={LayersIcon} />
          <p className="button-text">podloga</p>
        </button>
      </div>
    </>
  );
};
export default SideButtons;
