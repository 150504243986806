import { FunctionComponent } from "react";

import { vlastovnica } from "../../../../../table/utils";
import { Accordion } from "../Accordion";

interface ListBProps {
  setListBSharesExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  listBSharesExpanded: boolean;
  setListBSharesOwnersExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  listBSharesOwnersExpanded: boolean;
  setListBShares_shareEntriesExpanded: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  listBShares_shareEntriesExpanded: boolean;
  listB: any;
}

export const ListB: FunctionComponent<ListBProps> = ({
  setListBSharesExpanded,
  listBSharesExpanded,
  listB,
}) => {
  return (
    <div className="accordion-content">
      <>
        <Accordion
          setExpanded={setListBSharesExpanded}
          expanded={listBSharesExpanded}
          title="Vlastovnice"
          listType="vlastovnica"
          data={listB}
          columnNames={vlastovnica}
        />
      </>
    </div>
  );
};
