import React, { createContext, useContext, useState } from "react";

interface ToolsContextType {
  layer: boolean;
  setLayer: React.Dispatch<React.SetStateAction<boolean>>;
  parcelId: string;
  setParcelId: React.Dispatch<React.SetStateAction<string>>;
}
const ToolsContext = createContext<ToolsContextType>({
  layer: true,
  setLayer: () => null,
  parcelId: "",
  setParcelId: () => null,
});
interface ToolsProviderProps {
  children: React.ReactNode;
}
const ToolsProvider = ({ children }: ToolsProviderProps) => {
  const [layer, setLayer] = useState<boolean>(true);
  const [parcelId, setParcelId] = useState<string>("");
  return (
    <ToolsContext.Provider
      value={{
        layer,
        setLayer,
        parcelId,
        setParcelId,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
};
export const useToolsState = () => useContext(ToolsContext);
export default ToolsProvider;
