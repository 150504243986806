import axios from "axios";

export const extractCapturedParcels = async (extractedNames: string[]) => {
  return await axios.post(
    `https://webgis.netscale.agency/data/cadastre/export`,
    extractedNames,
    {
      responseType: "blob",
    }
  );
};
export const extractCapturedZKParcels = async (extractedNames: string[]) => {
  return await axios.post(
    `https://webgis.netscale.agency/data/land-registry/export`,
    extractedNames,
    {
      responseType: "blob",
    }
  );
};
