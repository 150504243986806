import { FunctionComponent } from "react";

interface TableHeaderProps {
  columnNames?: string[];
}

export const RenderTableHeader: FunctionComponent<TableHeaderProps> = ({
  columnNames,
}) => {
  if (!Array.isArray(columnNames)) {
    console.error("columnNames is not an array:", columnNames);
    return null;
  }

  return (
    <tr>
      {columnNames.map((columnName, index) => (
        <th style={{ textAlign: "left" }} key={index} className="">
          {columnName}
        </th>
      ))}
    </tr>
  );
};
