import L from "leaflet";
import { FunctionComponent } from "react";
import { Marker, Polyline } from "react-leaflet";
import { ZonaLayer } from "../api/getSpacialPlans";
import { spatialPlanStyle } from "../utils/spatialPlanPolygons";

export interface PolygonWithMarkerLayerProps {
  layersData?: {
    text: string | null;
    typefill: number;
    typeLayer: ZonaLayer;
    coordinates: any[];
  }[];
  display: boolean;
}
//TODO: replace any with correct type
export const PolygonWithMarkerLayer: FunctionComponent<
  PolygonWithMarkerLayerProps
> = ({ layersData, display }) => {
  return (
    <>
      {layersData &&
        layersData.length > 0 &&
        display &&
        layersData?.map((it: any, index: number) => {
          return (
            <div key={index}>
              {it.text !== null && (
                <Marker
                  position={it.coordinates}
                  icon={L.divIcon({
                    html: `<div>${it.text}</div>`,
                    className: "marker",
                  })}
                ></Marker>
              )}
              <Polyline
                key={index}
                positions={it.coordinates[0]}
                color={spatialPlanStyle(it.typefill, it.typeLayer)?.color}
                fill={spatialPlanStyle(it.typefill, it.typeLayer)?.fill}
                fillColor={spatialPlanStyle(it.typefill, it.typeLayer)?.color}
              />
            </div>
          );
        })}
    </>
  );
};
