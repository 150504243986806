import { FunctionComponent } from "react";
import ChevronDownIcon from "../../../../../assets/svg/ChevronDownIcon";
import ChevronUpIcon from "../../../../../assets/svg/ChevronUpIcon";
import { Table } from "../../../../table/Table";

interface AccordionProps {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  title: string;
  listType: string;
  data: any;
  columnNames: any[];
}

export const Accordion: FunctionComponent<AccordionProps> = ({
  setExpanded,
  expanded,
  title,
  listType,
  data,
  columnNames,
}) => {
  return (
    <div
      className="accordion-content-row"
      style={{ paddingTop: 12, paddingBottom: 12 }}
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className="accordion-header-container"
      >
        <p className="modal-item-text">{title}</p>
        {expanded ? (
          <ChevronUpIcon height={21} width={21} />
        ) : (
          <ChevronDownIcon height={21} width={21} />
        )}
      </div>
      {expanded ? (
        <div className="modal-content">
          <Table listType={listType} data={data} columnNames={columnNames} />
        </div>
      ) : null}
    </div>
  );
};
