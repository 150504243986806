import { ZonaLayer } from "../api/getSpacialPlans";

export const spatialPlanStyle = (
  tipIspune: number,
  tipProstornogPlana: ZonaLayer
) => {
  switch (tipProstornogPlana) {
    case "C10003B-REKONSTR_DJELA_NASELJA":
      return { color: "#ffff91", fill: tipIspunePP(tipIspune) }; // Light Yellow
    case "B08108A-POSEBNI_REZERVAT":
      return { color: "#ff9999", fill: tipIspunePP(tipIspune) }; // Light Red
    case "BRODICE":
      return { color: "#99ccff", fill: tipIspunePP(tipIspune) }; // Light Blue
    case "D052---BLOKOVI":
      return { color: "#000000", fill: tipIspunePP(tipIspune) }; // black
    case "C06207-KANAL_OBORINSKE_VODE_PLAN":
      return { color: "#66ff99", fill: tipIspunePP(tipIspune) }; // Light Green
    case "C08120A_POV_GRAD_CJELINE_SELO":
      return { color: "#cc99ff", fill: tipIspunePP(tipIspune) }; // Lavender
    case "C08221A-LUCKO_PODRUCJE":
      return { color: "#ffcc66", fill: tipIspunePP(tipIspune) }; // Light Orange
    case "C10--BLOKOVI":
      return { color: "red", fill: tipIspunePP(tipIspune) }; // Coral
    case "C10004B-NOVA_GRADNJA":
      return { color: "#66ccff", fill: tipIspunePP(tipIspune) }; // Sky Blue
    case "C10005B-JEDNOOBITELJSKI_OBJEKTI":
      return { color: "red", fill: tipIspunePP(tipIspune) }; // Light Lime jebo ga jarac triba mash al me boli pipi trenutno
    case "D00011-OBUHVAR_PROSTORNOG_PLANA":
      return { color: "#ff6699", fill: tipIspunePP(tipIspune) }; // Pink
    case "D02001A-STAMBENA_N":
      return { color: "#ffff7f", fill: tipIspunePP(tipIspune) };
    case "D02002A-MJESOVITA_N":
      return { color: "#fedf7e", fill: tipIspunePP(tipIspune) };
    case "D02003A-JAVNA_DRUSTVENA_N":
      return { color: "#ff9e7e", fill: tipIspunePP(tipIspune) };
    case "D02006A-G_UGOSTITELJ_TURIST_N":
      return { color: "#fe7e7f", fill: tipIspunePP(tipIspune) };
    case "D02008A-SPORTSKO_REKREACIJSKA_N":
      return { color: "#7edffe", fill: tipIspunePP(tipIspune) };
    case "D02010A-ZASTITNE_ZELENE_POVRS":
      return { color: "#befe7f", fill: tipIspunePP(tipIspune) };
    case "D02012A-POVRSINE_INFRASTRUKTURE":
      return { color: "#ff6666", fill: tipIspunePP(tipIspune) }; // Soft Red
    case "D03001-KONSTR_CESTE_OS":
      return { color: "red", fill: tipIspunePP(tipIspune) }; // Pale Mint
    case "D03005-PROFIL_CESTE_RUBNJAK":
      return { color: "black", fill: tipIspunePP(tipIspune) }; // Periwinkle
    case "D03009A-KOLNO_PJESACKE_POVRSINE":
      return { color: "#757575", fill: tipIspunePP(tipIspune) };
    case "D03014A-JAVNO_PARKING_GARAZA":
      return { color: "#66ffff", fill: tipIspunePP(tipIspune) }; // Cyan
    case "D04001-TK_PODZEMNI_VOD":
      return { color: "#ffcc33", fill: tipIspunePP(tipIspune) }; // Light Orange
    case "D04003-TK_PODZEMNI_VOD_PLAN":
      return { color: "#66cc66", fill: tipIspunePP(tipIspune) }; // Light Forest Green
    case "D05204-ELEKTRIKA_10KV":
      return { color: "#9933ff", fill: tipIspunePP(tipIspune) }; // Violet
    case "D05205-ELEKTRIKA_10KV_UKIDANJE":
      return { color: "#33ccff", fill: tipIspunePP(tipIspune) }; // Light Blue
    case "D05206-ELEKTRIKA_10KV_PLAN":
      return { color: "#ff33cc", fill: tipIspunePP(tipIspune) }; // Bright Pink
    case "D06101--VODOVOD":
      return { color: "#3366ff", fill: tipIspunePP(tipIspune) }; // Bright Blue
    case "D06103-VODOVOD_PLAN":
      return { color: "#33ccff", fill: tipIspunePP(tipIspune) }; // Light Blue
    case "D06104-VODOVOD_VISA_RAZINA":
      return { color: "#0033cc", fill: tipIspunePP(tipIspune) }; // Deep Blue
    case "D06201-ODVODNJA":
      return { color: "#3366ff", fill: tipIspunePP(tipIspune) }; // Royal Blue
    case "D09106A-PREPARCELACIJA":
      return { color: "#66acff", fill: tipIspunePP(tipIspune) }; // Light Blue
    case "D10002-G_GRADEVINSKE_CESTICE":
      return { color: "#ff6666", fill: tipIspunePP(tipIspune) }; // Soft Red
    case "_oznaka prostorne cjeline":
      return { color: "red", fill: tipIspunePP(tipIspune) }; // iz nekog razloga ima fill
  }
};

const tipIspunePP = (tipIspune: number) => {
  if (tipIspune === 0 || tipIspune === 2) return false;
  else return true;
};
