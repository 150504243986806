import { FunctionComponent } from "react";
import { Opis } from "../../map/components/infoModal/infoModalInterfaces";

interface ListTypeProps {
  data?: any[];
}

export const AEntries: FunctionComponent<ListTypeProps> = ({ data }) => {
  return (
    <>
      {data?.map((aEntry: any, entryIndex: any) => (
        <>
          <tr key={entryIndex}>
            <td style={{ border: "1px solid transparent" }}>
              {entryIndex + 1}
            </td>
            <td style={{ border: "1px solid transparent" }}>
              {aEntry?.brojCestice ? aEntry?.brojCestice : "Nema podataka"}
            </td>
            <td style={{ border: "1px solid transparent" }}>
              {aEntry?.oznakaZemljista
                ? aEntry?.oznakaZemljista
                : "Nema podataka"}
            </td>
            <td style={{ border: "1px solid transparent" }}>
              {aEntry?.povrsina ? aEntry?.povrsina : "Nema podataka"}
            </td>
            <td style={{ border: "1px solid transparent" }}>
              {aEntry?.primjedba ? aEntry?.primjedba : "Nema podataka"}
            </td>
          </tr>
          {aEntry.opisi.map((opis: Opis, index: number) => (
            <tr
              key={`${index}-${entryIndex}`}
              style={{
                borderLeft: "none",
                borderRight: "none",
                borderTop: "1px solid black",
              }}
            >
              <td
                colSpan={5}
                style={{
                  marginBottom: 24,
                  marginTop: 24,
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
              >
                {opis.opis}
              </td>
            </tr>
          ))}
        </>
      ))}
    </>
  );
};
