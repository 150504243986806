import { FunctionComponent, useState } from "react";
import { ListA } from "./Lists/ListA";
import { ListB } from "./Lists/ListB";
import { ListC } from "./Lists/ListC";

interface SecondTabProps {
  mainBookId: string;
  listA: any[];
  listB: any[];
  listC: any[];
  landBooknumber: string;
}

export const SecondTab: FunctionComponent<SecondTabProps> = ({
  mainBookId,
  listA,
  listB,
  landBooknumber,
  listC,
}) => {
  const [aEntriesExpanded, setAEntriesExpanded] = useState(false);

  const [listBSharesExpanded, setListBSharesExpanded] = useState(false);
  const [listCSharesExpanded, setListCSharesExpanded] = useState(false);
  const [listBSharesOwnersExpanded, setListBSharesOwnersExpanded] =
    useState(false);
  const [
    listBShares_shareEntriesExpanded,
    setListBShares_shareEntriesExpanded,
  ] = useState(false);
  return (
    <div className="modal-content">
      <div className="modal-content-row">
        <p className="modal-item-text">Broj glavne knjige:</p>
        <p className="modal-text">{mainBookId}</p>
      </div>
      <div className="modal-content-row">
        <p className="modal-item-text">Broj uloška:</p>
        <p className="modal-text">{landBooknumber}</p>
      </div>
      <ListA
        setPosjedovnicaExpanded={setAEntriesExpanded}
        aEntriesExpanded={aEntriesExpanded}
        listA={listA}
      />

      <ListB
        setListBSharesExpanded={setListBSharesExpanded}
        listBSharesExpanded={listBSharesExpanded}
        setListBSharesOwnersExpanded={setListBSharesOwnersExpanded}
        listBSharesOwnersExpanded={listBSharesOwnersExpanded}
        setListBShares_shareEntriesExpanded={
          setListBShares_shareEntriesExpanded
        }
        listBShares_shareEntriesExpanded={listBShares_shareEntriesExpanded}
        listB={listB}
      />

      <ListC
        cEntriesExpanded={listCSharesExpanded}
        setPosjedovnicaExpanded={setListCSharesExpanded}
        listC={listC}
      />
    </div>
  );
};
