import axios from "axios";

export type ZonaLayer =
  | "D03014A-JAVNO_PARKING_GARAZA"
  | "D02008A-SPORTSKO_REKREACIJSKA_N"
  | "x-koncesija"
  | "D052---BLOKOVI"
  | "D06101--VODOVOD"
  | "C10--BLOKOVI"
  | "BRODICE"
  | "D03005-PROFIL_CESTE_RUBNJAK"
  | "B08108A-POSEBNI_REZERVAT"
  | "D02003A-JAVNA_DRUSTVENA_N"
  | "D10002-G_GRADEVINSKE_CESTICE"
  | "D02010A-ZASTITNE_ZELENE_POVRS"
  | "D06104-VODOVOD_VISA_RAZINA"
  | "C10003B-REKONSTR_DJELA_NASELJA"
  | "C10004B-NOVA_GRADNJA"
  | "D04001-TK_PODZEMNI_VOD"
  | "D04003-TK_PODZEMNI_VOD_PLAN"
  | "C08120A_POV_GRAD_CJELINE_SELO"
  | "D05206-ELEKTRIKA_10KV_PLAN"
  | "D06201-ODVODNJA"
  | "D02002A-MJESOVITA_N"
  | "D03009A-KOLNO_PJESACKE_POVRSINE"
  | "C10005B-JEDNOOBITELJSKI_OBJEKTI"
  | "D09106A-PREPARCELACIJA"
  | "C03---BLOKOVI"
  | "D06103-VODOVOD_PLAN"
  | "D00011-OBUHVAR_PROSTORNOG_PLANA"
  | "D03001-KONSTR_CESTE_OS"
  | "D02012A-POVRSINE_INFRASTRUKTURE"
  | "D02001A-STAMBENA_N"
  | "C08221A-LUCKO_PODRUCJE"
  | "_oznaka prostorne cjeline"
  | "D02006A-G_UGOSTITELJ_TURIST_N"
  | "C06207-KANAL_OBORINSKE_VODE_PLAN"
  | "D05205-ELEKTRIKA_10KV_UKIDANJE"
  | "D05204-ELEKTRIKA_10KV"
  | "x-sivo1";

export const getSpacialPlans = async () => {
  return await axios.get(
    `https://webgis.netscale.agency/prostorni-plan/get-all`
  );
};
