import axios from "axios";

export interface Parcel {
  id: string;
  municipality: string;
  number: string;
  descriptiveAddress: string;
  graphicArea: string;
  detailedSheet: string;
  status: string;
  annotation: Annotation;
}

interface Annotation {
  id: number;
  municipalityNumber: string;
  parcelNumber: string;
  rotation: number;
}
export const getParcelByName = (name: string) => {
  return axios.get<Parcel[]>(
    `https://webgis.netscale.agency/data/pretrazi-po-broju?parcelNumber=${name}`
  );
};
