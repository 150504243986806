import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { postGeometries } from "../components/map/api/postGeometries";

interface GeoJSONData {
  parcelsData: any;
  buildingsData: any;
  utilityChargesData: any;
  billboardsData: any;
  kucniBrojeviData: any;
  publicLightingData: any;
  objectRecordsData: any;
}
const GeoJSONContext = createContext<GeoJSONData>({
  parcelsData: null,
  buildingsData: null,
  utilityChargesData: null,
  kucniBrojeviData: null,
  billboardsData: null,
  publicLightingData: null,
  objectRecordsData: null,
});
export const useGeoJSONData = () => useContext(GeoJSONContext);
interface GeoJSONProviderProps {
  children: ReactNode;
}
const GeoJSONProvider: React.FC<GeoJSONProviderProps> = ({ children }) => {
  const [parcelsData, setParcelsData] = useState(null);
  const [buildingsData, setBuildingsData] = useState(null);
  const [kucniBrojeviData, setKucniBrojeviData] = useState(null);
  const [utilityChargesData, setUtilityChargesData] = useState(null);
  const [billboardsData, setBillboardsData] = useState(null);
  const [publicLightingData, setPublicLightingData] = useState(null);
  const [objectRecordsData, setObjectRecordsData] = useState(null);
  useMemo(() => {
    const requestBody = {
      bounds: {
        southWest: {
          lat: 43.005275,
          lng: 16.045876,
        },
        northEast: {
          lat: 43.080925,
          lng: 16.269379,
        },
      },

      includeList: [
        "Parcels",
        "Buildings",
        "EvidencijaObjekata",
        "KucniBrojevi",
        "KomunalnaNaknada",
      ],
    };
    postGeometries(requestBody)
      .then((response) => {
        const data = response.data;
        setParcelsData(data.geometries.Parcels);
        setBuildingsData(data.geometries.Buildings);
        setKucniBrojeviData(data.geometries.KucniBrojevi);
        setUtilityChargesData(data.geometries.KomunalnaNaknada);
        setBillboardsData(data.geometries.Billboards);
        setPublicLightingData(data.geometries.PublicLighting);
        setObjectRecordsData(data.geometries.EvidencijaObjekata);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);
  const geoJSONData = {
    parcelsData,
    buildingsData,
    utilityChargesData,
    billboardsData,
    kucniBrojeviData,
    publicLightingData,
    objectRecordsData,
  };
  return (
    <GeoJSONContext.Provider value={geoJSONData}>
      {children}
    </GeoJSONContext.Provider>
  );
};
export default GeoJSONProvider;
