import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { getSpacialPlans } from "../components/map/api/getSpacialPlans";

interface SpatialEntity {
  id: string;
  tipProstornogEnum: number;
  tipGeometrijeEnum: number;
  layer: string;
  text: string | null;
  geometry: string;
}

interface SpatialPlansData {
  layersData: any;
}
const SpatialPlansContext = createContext<SpatialPlansData>({
  layersData: null,
});
export const useSpatialPlansData = () => useContext(SpatialPlansContext);
interface SpatialDataProviderProps {
  children: ReactNode;
}
const SpatialPlansProvider: React.FC<SpatialDataProviderProps> = ({
  children,
}) => {
  const [layersData, setLayersData] = useState<SpatialEntity[]>([]);

  useMemo(() => {
    getSpacialPlans()
      .then((response) => {
        const data = response.data;
        setLayersData(data || []);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const spatialPlansData = {
    layersData,
  };

  return (
    <SpatialPlansContext.Provider value={spatialPlansData}>
      {children}
    </SpatialPlansContext.Provider>
  );
};
export default SpatialPlansProvider;
