import axios from "axios";

interface GeometriesRequestObject {
  bounds: {
    southWest: {
      lat: number;
      lng: number;
    };
    northEast: {
      lat: number;
      lng: number;
    };
  };
  includeList: string[];
}
export const postGeometries = async (data: GeometriesRequestObject) => {
  return await axios.post("https://webgis.netscale.agency/Geometries", data);
};
