import { FunctionComponent } from "react";
import { AEntries } from "./ListTypes/Posjedovnica";
import { Teretovnica } from "./ListTypes/Teretovnica";
import { Bodies } from "./ListTypes/Vlastovnica";

interface TableDataProps<T> {
  data?: T[];
  listType?: string;
}

export const RenderTableData: FunctionComponent<TableDataProps<any>> = ({
  data,
  listType,
}) => {
  return (
    <>
      {listType === "posjedovnica" && <AEntries data={data} />}
      {listType === "vlastovnica" && <Bodies data={data} />}
      {listType === "teretovnica" && <Teretovnica data={data} />}
    </>
  );
};
