export const filterSpatialPlan = (layers: any, plan: number) => {
  if (!layers) return undefined;
  return layers
    .filter((item: any) => item.tipProstornogEnum === plan)
    ?.map((item: any) => ({
      typeLayer: item.layer,
      text: item.text,
      typefill: item.tipGeometrijeEnum,
      ...JSON.parse(item.geometry),
    }));
};
