import { useState } from "react";
import "./Header.css";
import HeaderMenu from "./components/headerMenu/HeaderMenu";
// Assets
import Logo from "../../assets/svg/Logo.svg";
import MenuIcon from "../../assets/svg/MenuIcon.svg";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      {openMenu && (
        <HeaderMenu closeMenu={handleOpenMenu} setOpenMenu={setOpenMenu} />
      )}
      <div className="header-container">
        <div className="header-left-box">
          <button onClick={handleOpenMenu} className="menu-button">
            <img alt="menu" src={MenuIcon} className="menu-icon" />
          </button>
          <img alt="logo" src={Logo} />
        </div>
      </div>
    </>
  );
};
export default Header;
