import { FunctionComponent } from "react";

interface ListTypeProps {
  data?: any[];
}

export const Teretovnica: FunctionComponent<ListTypeProps> = ({ data }) => {
  return (
    <>
      {data?.length !== 0 ? (
        data?.map((teret: any, entryIndex: any) => (
          <>
            <tr key={entryIndex}>
              <td style={{ border: "1px solid transparent" }}>
                {entryIndex + 1}
              </td>
              <td style={{ border: "1px solid transparent", width: "100%" }}>
                {teret?.opis ? teret?.opis : "Nema podataka"}
              </td>
              <td style={{ border: "1px solid transparent" }}>
                {teret?.iznosTereta ? teret?.iznosTereta : "Nema podataka"}
              </td>

              <td style={{ border: "1px solid transparent" }}>
                {teret?.primjedba ? teret?.primjedba : "Nema podataka"}
              </td>
            </tr>
          </>
        ))
      ) : (
        <tr>
          <td colSpan={3}>Nema tereta</td>
        </tr>
      )}
    </>
  );
};
