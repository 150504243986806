import L, { LatLngBoundsLiteral } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-geometryutil";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  FeatureGroup,
  GeoJSON,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";

import { EditControl } from "react-leaflet-draw";
import { HeaderMenuForm } from "../../App";
import { useGeoJSONData } from "../../context/GeoJSONContext";
import { useSpatialPlansData } from "../../context/SpatialPlansContext";
import { useToolsState } from "../../context/ToolsContext";
import Modal from "../header/components/loadingModal/Modal";
import "./Map.css";
import { PolygonWithMarkerLayer } from "./components/PolygonWithMarkerLayer";
import { InfoModal } from "./components/infoModal/InfoModal";
import { InfoModalKomunalna } from "./components/infoModal/InfoModalKomunalna";
import ZoomLevelHandler from "./utils/ZoomLevelHandler";
import { filterSpatialPlan } from "./utils/filterSpatialPlan";
import {
  handleShapeCreated,
  handleShapeEdited,
} from "./utils/handleShapeCreated";
import { polygonAnimationStyle } from "./utils/polygonAnimationStyle";

const Map = () => {
  const { layer, setParcelId } = useToolsState();
  const [showModal, setShowModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedKomunalnaId, setSelectedKomunalnaId] = useState<number | null>(
    null
  );
  const [modal, setModal] = useState(false);
  const [modalKomunalna, setModalKomunalna] = useState(false);
  const formMethods = useFormContext<HeaderMenuForm>();
  const customLayerBounds: LatLngBoundsLiteral = [
    [43.005275, 16.045876],
    [43.080925, 16.269379],
  ];
  const {
    parcelsData,
    buildingsData,
    utilityChargesData,
    billboardsData,
    kucniBrojeviData,
    publicLightingData,
    objectRecordsData,
  } = useGeoJSONData();
  const { layersData } = useSpatialPlansData();

  const [loading, setLoading] = useState(false);
  const [loadingKomunalna, setLoadingKomunalna] = useState(false);
  const formData = formMethods.watch();
  const prostorniPlanovi = [
    formData.basicModules.spatialPlan.koristenje,
    formData.basicModules.spatialPlan.prometnice,
    formData.basicModules.spatialPlan.mreza,
    formData.basicModules.spatialPlan.voda,
    formData.basicModules.spatialPlan.otpadnaVoda,
    formData.basicModules.spatialPlan.odvodnjaKise,
    formData.basicModules.spatialPlan.oblici,
    formData.basicModules.spatialPlan.naciniGradnje,
    formData.basicModules.spatialPlan.prijedlog,
  ];
  const handleClick = (item?: any) => {
    setParcelId(item && item?.properties ? item?.properties.name : "");
    setLoading(true);
    setModal(true);
  };
  const handleClickKomunalnaNaknada = (item?: any) => {
    setSelectedKomunalnaId(
      item && item?.properties ? item?.properties.id : null
    );
    setLoadingKomunalna(true);
    setModalKomunalna(true);
  };
  const handleModalCloseKomunalna = () => {
    setModalKomunalna(false);
    setLoadingKomunalna(false);
    setSelectedKomunalnaId(null);
  };
  const handleModalClose = () => {
    setModal(false);
    setLoading(false);
    setParcelId("");
  };

  return (
    <>
      {modal && (
        <InfoModal
          setDownloadModal={setShowModal}
          toggleModal={handleModalClose}
          loading={loading}
          setLoading={setLoading}
          ids={selectedIds}
          setIds={setSelectedIds}
          setModal={setModal}
        />
      )}
      {modalKomunalna && (
        <InfoModalKomunalna
          id={selectedKomunalnaId}
          handleClose={handleModalCloseKomunalna}
        />
      )}
      <MapContainer
        id="map"
        center={[43.024308, 16.208355]}
        zoom={16}
        minZoom={10}
        maxZoom={21}
        maxBounds={customLayerBounds}
        zoomControl={false}
        className="map-container"
      >
        <TileLayer
          url={
            layer
              ? "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              : "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          }
        />
        {layer && (
          <TileLayer
            url="https://webgis.netscale.agency/tiles/{z}/{x}/{y}.png"
            tms={true}
            noWrap={true}
            bounds={customLayerBounds}
            maxZoom={21}
            minZoom={16}
            tileSize={256}
          />
        )}

        {prostorniPlanovi.map((display, index) => (
          <PolygonWithMarkerLayer
            display={display}
            layersData={filterSpatialPlan(layersData, index)}
          />
        ))}

        {parcelsData && (
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={(e) => {
                handleShapeCreated(e, parcelsData, setModal, setSelectedIds);
                handleClick();
              }}
              onEdited={(e) => {
                handleShapeEdited(e, parcelsData, setModal, setSelectedIds);
                handleClick();
              }}
              draw={{
                polyline: false,
                circle: false,
                circlemarker: false,
                marker: false,
                rectangle: true,
                polygon: true,
              }}
            />
          </FeatureGroup>
        )}
        {parcelsData && formData.basicModules.cadastre.parcels && (
          <GeoJSON
            onEachFeature={(feature, layer) => {
              layer.on("click", () => {
                handleClick(feature);
              });
              layer.on("mouseover", () => {
                //@ts-ignore
                layer.setStyle(polygonAnimationStyle("orange", true));
              });
              layer.on("mouseout", () => {
                //@ts-ignore
                layer.setStyle(polygonAnimationStyle("orange", false));
              });
            }}
            children={
              parcelsData
                ? parcelsData.features.map((item: any, key: number) => (
                    <Marker
                      key={key}
                      eventHandlers={{ click: () => handleClick(item) }}
                      position={[
                        item.properties.anchor.coordinates[1],
                        item.properties.anchor.coordinates[0],
                      ]}
                      icon={L.divIcon({
                        html: item.properties.name,
                        className: "marker",
                      })}
                    ></Marker>
                  ))
                : []
            }
            data={parcelsData}
            style={{ color: "orange" }}
          />
        )}
        {buildingsData && formData.basicModules.cadastre.buildings && (
          <GeoJSON
            children={
              kucniBrojeviData
                ? kucniBrojeviData.features.map((item: any, key: number) => (
                    <Marker
                      key={key}
                      position={[
                        item.properties.anchor.coordinates[1],
                        item.properties.anchor.coordinates[0],
                      ]}
                      icon={L.divIcon({
                        html: item.properties.name,
                        className: "marker-kucni",
                      })}
                    />
                  ))
                : []
            }
            data={buildingsData}
            style={{ color: "blue" }}
          />
        )}
        {utilityChargesData &&
          formData.communalEconomy.communalFee &&
          utilityChargesData.features.map((item: any, key: any) => (
            <Marker
              key={key}
              position={[
                item.properties.anchor.coordinates[1],
                item.properties.anchor.coordinates[0],
              ]}
              icon={L.divIcon({
                html: `<div style="width: 16px; height: 16px;border-radius:16px; background-color: red;"></div>`,
                className: "marker",
              })}
            >
              <Popup className="popup" closeButton={false}>
                {loadingKomunalna ? (
                  <div
                    className="btn-loading-spinner"
                    onClick={handleModalClose}
                  />
                ) : (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickKomunalnaNaknada(item);
                    }}
                    className="popup-button"
                    key={key}
                  >
                    {loadingKomunalna ? (
                      <>
                        <div className="btn-loading-spinner" />
                      </>
                    ) : (
                      <p>prikaz podataka komunalne naknade</p>
                    )}
                  </button>
                )}
              </Popup>
            </Marker>
          ))}
        {billboardsData && formData.other.advertisementBoards && (
          <GeoJSON data={billboardsData} style={{ color: "white" }} />
        )}
        {publicLightingData &&
          formData.communalInfrastructure.publicIllumination && (
            <GeoJSON data={publicLightingData} style={{ color: "green" }} />
          )}
        {objectRecordsData && formData.communalEconomy.objectEvidentation && (
          <GeoJSON data={objectRecordsData} style={{ color: "red" }} />
        )}
        <ZoomLevelHandler />
        <ZoomControl position="topright" />
      </MapContainer>
      {showModal && (
        <Modal
          title="Pričekajte download"
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
export default Map;
