export const polygonAnimationStyle = (color: string, active?: boolean) => {
  return active
    ? {
        color: color,
        weight: 3,
        opacity: 0.6,
        fillOpacity: 0.6,
        fillColor: color,
      }
    : {
        color: color,
        weight: 3,
        opacity: 0.8,
        fillOpacity: 0.2,
        fillColor: color,
      };
};
