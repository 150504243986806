export const posjedovnica = [
  "Rbr.",
  "Broj zemljišta (kat. čestice)",
  "Oznaka zamljišta",
  "Površina(m2)",
  "Primjedba",
];

export const vlastovnica = ["Rbr.", "Vlasnik", "udio", "Primjedba"];
export const teretovnica = ["Rbr.", "Sadržaj", "Iznos", "Primjedba"];
