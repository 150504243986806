import { SpatialPlanLayer } from "./SpacialPlanSublayerList";

export type SpatialPlanNames =
  | "basicModules.spatialPlan.koristenje"
  | "basicModules.spatialPlan.naciniGradnje"
  | "basicModules.spatialPlan.prometnice"
  | "basicModules.spatialPlan.mreza"
  | "basicModules.spatialPlan.voda"
  | "basicModules.spatialPlan.otpadnaVoda"
  | "basicModules.spatialPlan.odvodnjaKise"
  | "basicModules.spatialPlan.oblici"
  | "basicModules.spatialPlan.prijedlog";

interface SpatialPlanData {
  name: SpatialPlanNames;
  label: string;
  spatialPlanLayers: SpatialPlanLayer[];
}
export const spacialPlansCheckboxData: SpatialPlanData[] = [
  {
    name: "basicModules.spatialPlan.koristenje",
    label: "Korištenje i namjena",
    spatialPlanLayers: [
      { name: "STAMBENA NAMJENA (S)", color: "#ffff7f" },
      { name: "MJEŠOVITA NAMJENA (M1)", color: "#fedf7e" },
      {
        name: "GOSPODARSKA NAMJENA UGOSTITELJSKO TURISTICKA (T1)",
        color: "#fe7e7f",
      },
      { name: "GOSPODARSKA NAMJENA POSLOVNA (K1)", color: "#febe7f" },
      { name: "JAVNA I DRUŠTVENA NAMJENA (D)", color: "#ff9e7e" },
      { name: "ŠPORTSKO REKREACIJSKA (R)", color: "#7edffe" },
      { name: "JAVNE ZELENE POVRŠINE (Z1)", color: "#9eff7e" },
      { name: "ZAŠTITNE ZELENE POVRŠINE (Z)", color: "#befe7f" },
      { name: "POVRŠINE INFRASTRUKTURNIH SUSTAVA (IS)", color: "#ffffff" },
      { name: "MORSKE LUKE", color: "blue" },
    ],
  },
  {
    name: "basicModules.spatialPlan.naciniGradnje",
    label: "Nacin gradnje",
    spatialPlanLayers: [
      {
        name: "VIŠEOBITELJSKA DO TRI STANA (SS- SAMOSTOJEĆI, D- DVOJNI)",
        color: "red",
      },
      {
        name: "NAČIN GRADNJE PREMA UVJETIMA ZA PROSTORNU CJELINU (1.1., 1.2., 1.2.a., 1.3, 1.4)",
        color: "transparent",
      },
      {
        name: "JAVNA I DRUŠTVENA NAMJENA (2.)",
        color: "red",
      },
      {
        name: "GOSPODARSKA NAMJENA (3.)",
        color: "red",
      },
      {
        name: "AČIN GRADNJE PREMA UVJETIMA ZA PROSTORNU CJELINU R. (R.)",
        color: "red",
      },
      {
        name: "NAČIN GRADNJE PREMA UVJETIMA ZA PROSTORNU CJELINU LUKE (4.)",
        color: "red",
      },
      {
        name: "NAČIN GRADNJE PREMA UVJETIMA ZA UREĐENJE JAVNIH ZELENIH POVRŠINA (5.)",
        color: "red",
      },
    ],
  },
  {
    name: "basicModules.spatialPlan.prometnice",
    label: "Prometna mreža",
    spatialPlanLayers: [
      { name: "PARKING", color: "#66ffff" },
      { name: "KOLNO PJEŠAČKE POVRŠINE", color: "#757575" },
      { name: "KONSTR. CESTE OS", color: "red" },
      { name: "PROFIL CESTE RUBNJAK", color: "black" },
    ],
  },
  {
    name: "basicModules.spatialPlan.mreza",
    label: "Telekomunikacijska i energetska mreža",
    spatialPlanLayers: [
      { name: "PODZEMNI VODOVI", color: "#ffcc33" },
      { name: "PODZEMNI VODOVI PLAN", color: "#66cc66" },
      { name: "10KV ELEKTRIČNI VOD UKIDANJE", color: "#33ccff" },
      { name: "10KV ELEKTRIČNI VOD PLAN", color: "#ff33cc" },
      { name: "10KV ELEKTRIČNI VOD", color: "#9933ff" },
      { name: "D052---BLOKOVI", color: "#000000" },
    ],
  },
  {
    name: "basicModules.spatialPlan.voda",
    label: "Vodoobskrba",
    spatialPlanLayers: [
      { name: "VODOVOD", color: "#3366ff" },
      { name: "VODOVOD PLAN", color: "#33ccff" },
      { name: "VODOVOD VIŠA RAZINA", color: "#0033cc" },
    ],
  },
  {
    name: "basicModules.spatialPlan.otpadnaVoda",
    label: "Odvodnja otpadnih voda",
    spatialPlanLayers: [{ name: "ODVODNJA OTPADNIH VODA", color: "#3366ff" }],
  },
  {
    name: "basicModules.spatialPlan.odvodnjaKise",
    label: "Odvodnja oborinskih voda",
    spatialPlanLayers: [{ name: "ODVODNJA OBORINSKIH VODA", color: "#66ff99" }],
  },
  {
    name: "basicModules.spatialPlan.oblici",
    label: "Oblici korištenja",
    spatialPlanLayers: [
      { name: "REKONSTRUKCIJA DJELA NASELJA", color: "#ffff91" },
      { name: "NOVA GRADNJA", color: "#66ccff" },
    ],
  },
  {
    name: "basicModules.spatialPlan.prijedlog",
    label: "Prijedlog parcelizacije",
    spatialPlanLayers: [
      { name: "GRAĐEVINSKE ČESTICE", color: "#ff6666" },
      { name: "PREPARCELACIJA", color: "#66acff" },
    ],
  },
];
