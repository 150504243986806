import { useEffect } from "react";
import { useMap } from "react-leaflet";

const ZoomLevelHandler = () => {
  const map = useMap();
  useEffect(() => {
    const handleZoomEnd = () => {
      const zoomLevel = map.getZoom();
      const displayText = zoomLevel < 18 || zoomLevel > 21 ? "none" : "block";
      document.documentElement.style.setProperty(
        "--dynamic-display-text",
        displayText
      );
    };
    map.on("zoomend", handleZoomEnd);
    return () => {
      map.off("zoomend", handleZoomEnd);
    };
  }, [map]);
  return null;
};
export default ZoomLevelHandler;
