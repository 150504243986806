import { FunctionComponent } from "react";

interface ListTypeProps {
  data?: any[];
}

export const Bodies: FunctionComponent<ListTypeProps> = ({ data }) => {
  return (
    <>
      {data?.map((body: any, bodyIndex: number) => (
        <tr key={bodyIndex + 1}>
          <td style={{ border: "1px solid transparent" }}>{bodyIndex + 1}</td>
          <td style={{ border: "1px solid transparent", width: "100%" }}>
            {body?.vlasnik
              ? body?.vlasnik.map((item: any) => (
                  <p>{`${item.ime}${item.oib ? `, ${item.oib}` : ""}`}</p>
                ))
              : "Nema podataka"}
          </td>
          <td style={{ border: "1px solid transparent" }}>
            {body?.udio ? body?.udio : "Nema podataka"}
          </td>
          <td style={{ border: "1px solid transparent" }}>
            {body?.primjedba ? body?.primjedba : "Nema podataka"}
          </td>
        </tr>
      ))}
    </>
  );
};
