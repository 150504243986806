import { FunctionComponent } from "react";

import { teretovnica } from "../../../../../table/utils";
import { Accordion } from "../Accordion";

interface ListCProps {
  setPosjedovnicaExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  cEntriesExpanded: boolean;
  listC: any;
}

export const ListC: FunctionComponent<ListCProps> = ({
  setPosjedovnicaExpanded,
  cEntriesExpanded,
  listC,
}) => {
  return (
    <div className="accordion-content">
      <Accordion
        setExpanded={setPosjedovnicaExpanded}
        expanded={cEntriesExpanded}
        title="Teretovnice"
        listType="teretovnica"
        data={listC}
        columnNames={teretovnica}
      />
    </div>
  );
};
