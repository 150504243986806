import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useToolsState } from "../../../../context/ToolsContext";
import {
  CatastreData,
  getLandRegistries,
  getParcelInfos,
} from "../../api/getParcelInfos";
import "./InfoModal.css";
// Assets
import CloseButtonIcon from "../../../../assets/svg/CloseButtonIcon.svg";
import { handleExportZemljisneKnjige } from "../../utils/handleShapeCreated";
import { Ownership } from "./infoModalInterfaces";
import { FirstTab } from "./Tabs/FirstTab";
import { SecondTab } from "./Tabs/SecondTab";

interface InfoModalProps {
  toggleModal: () => void;
  ids: string[];
  setIds?: (val: string[]) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InfoModal: FunctionComponent<InfoModalProps> = ({
  toggleModal,
  loading,
  setLoading,
  setModal,
  ids,
  setDownloadModal,
  setIds,
}) => {
  const { parcelId, setParcelId } = useToolsState();

  const [parcelInfoData, setParcelInfoData] = useState<CatastreData[]>();
  const [landRegistryData, setLandRegistryData] = useState<Ownership[]>();
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setModal(false);
        setIds?.([]);
        setParcelId("");
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getParcelInfos(
          [parcelId, ...ids].filter(Boolean)
        );

        setLoading(false);
        setParcelInfoData(response.data);
      } catch (error) {
        console.error("Error fetching parcel info:", error);
        setLoading(false);
      }
    };
    if (parcelId || ids.length > 0) {
      fetchData();
      getLandRegistries(
        [parcelId, ...ids]
          .map((parcel) => parcel.replace("*", ""))
          .filter(Boolean)
      )
        .then((res) => setLandRegistryData(res.data))
        .catch(() => {
          console.log("err");
        });
    }
  }, [parcelId]);
  return parcelInfoData ? (
    <div className="modal-overlay">
      <div className="modal-container" ref={modalRef}>
        <div className="modal-top-bar">
          <div style={{ gap: 16, display: "flex" }}>
            <button
              onClick={() =>
                handleExportZemljisneKnjige(
                  [parcelId, ...ids],
                  setDownloadModal
                )
              }
              className="modal-top-bar-button"
            >
              Export podataka parcele
            </button>
            <button
              onClick={() =>
                handleExportZemljisneKnjige(
                  [parcelId, ...ids],
                  setDownloadModal,
                  true
                )
              }
              className="modal-top-bar-button"
            >
              Export zemljišnih knjiga
            </button>
            {ids.length > 0 && (
              <select
                onChange={(e) => {
                  getParcelInfos(
                    e.target.value === "all" ? ids : [e.target.value]
                  )
                    .then((res) => setParcelInfoData(res.data))
                    .catch((err) => {
                      console.error(err);
                    });
                  getLandRegistries(
                    e.target.value === "all"
                      ? ids.map((parcel) => parcel.replace("*", ""))
                      : [e.target.value].map((parcel) =>
                          parcel.replace("*", "")
                        )
                  ).then((res) => setLandRegistryData(res.data));
                }}
              >
                <option value={"all"}>all</option>
                {ids.map((id) => (
                  <option value={id}>{id}</option>
                ))}
              </select>
            )}
          </div>
          <img
            alt="close"
            src={CloseButtonIcon}
            className="close-icon"
            onClick={() => {
              setIds?.([]);
              setParcelId("");

              toggleModal();
            }}
          />
        </div>
        <div className="tabs">
          <button
            className={
              activeTab === 1
                ? "modal-tab-button-active modal-tab"
                : "modal-tab-button modal-tab"
            }
            onClick={() => handleTabChange(1)}
          >
            <p className={activeTab === 2 ? "tab-title-active" : "tab-title"}>
              Katastar
            </p>
          </button>
          <button
            className={
              activeTab === 2
                ? "modal-tab-button-active modal-tab"
                : "modal-tab-button modal-tab"
            }
            onClick={() => handleTabChange(2)}
          >
            <p
              className={
                activeTab === 2
                  ? "tab-title-active modal-tab"
                  : "tab-title modal-tab"
              }
            >
              Zemljišne knjige
            </p>
          </button>
        </div>
        {activeTab === 1 &&
          parcelInfoData &&
          parcelInfoData.map((parcel) => <FirstTab parcelInfoData={parcel} />)}
        {activeTab === 2 && (
          <div>
            {landRegistryData?.map((land) => (
              <SecondTab
                listA={land.posjedovnice}
                mainBookId={`${land.brojGlavneKnjige}`}
                listB={land.vlastovnice}
                landBooknumber={land.brojUloska}
                listC={land.teretovnica.opisi}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
