import CloseIcon from "../../../../assets/svg/CloseIcon.svg";
import "./Modal.css";

interface ModalProps {
  title: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content-global">
        <div className="info-modal-global">
          <div className="loading-spinner"></div>
          <span className="modal-title header-modal-custom ">{title}</span>
          <button onClick={onClose} className="modal-close-btn">
            <img alt="" src={CloseIcon} style={{ width: 24, height: 24 }} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Modal;
