import React from "react";
import { Control, Controller } from "react-hook-form";
import "./Checkbox.css";
// Assets
import CheckIcon from "../../../../assets/svg/CheckIcon.svg";

export interface CheckboxProps {
  label?: string;
  name: string;
  control: Control<any>;
  triggerOnBlur?: (e: React.FocusEventHandler<HTMLInputElement>) => void;
  triggerOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dot?: boolean;
  dotColor?: string;
}
const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  control,
  triggerOnBlur,
  triggerOnChange,
  dot,
  dotColor,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <label className="checkbox-label">
          <input
            name={name}
            type="checkbox"
            checked={value}
            onBlur={onBlur ?? triggerOnBlur}
            onChange={onChange ?? triggerOnChange}
            className={`checkbox ${value && "checkbox-active"}`}
          />
          {label}
          {value && (
            <img alt="checkmark" src={CheckIcon} className="checkmark-icon" />
          )}
          {dot && (
            <div className="dot-mark" style={{ backgroundColor: dotColor }} />
          )}
        </label>
      )}
    />
  );
};
export default Checkbox;
