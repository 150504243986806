import { intersect } from "@turf/turf";
import {
  extractCapturedParcels,
  extractCapturedZKParcels,
} from "../api/extractCapturedParcels";

export const handleShapeCreated = async (
  e: any,
  parcelsData: any,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (!e || !e.layer) {
    console.error("Invalid event object or missing layer property");
    return;
  }
  const drawnLayer = e.layer;
  const capturedIds: string[] = [];
  parcelsData.features.forEach((parcel: any) => {
    const parcelGeometry = parcel.geometry;
    if (
      drawnLayer.toGeoJSON().geometry &&
      drawnLayer.toGeoJSON().geometry.type === "Polygon"
    ) {
      const drawnGeoJSON = drawnLayer.toGeoJSON();
      const drawnGeometry = drawnGeoJSON.geometry;
      const intersection = intersect(parcelGeometry, drawnGeometry);
      if (intersection) {
        capturedIds.push(parcel.properties.name);
      }
    }
  });
  setSelected(capturedIds);
  setShowModal(true);
  return capturedIds;
};
export const handleShapeEdited = async (
  e: any,
  parcelsData: any,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (!e || !e.layers) {
    console.error("Invalid event object or missing layers property");
    return;
  }
  const editedLayers = e.layers;
  editedLayers.eachLayer((layer: any) => {
    handleShapeCreated({ layer }, parcelsData, setShowModal, setSelected);
  });
};

export const handleExportZemljisneKnjige = async (
  capturedIds: string[],
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
  isZK?: boolean
) => {
  try {
    setShowModal(true);
    const axiosResponse = isZK
      ? await extractCapturedZKParcels(capturedIds)
      : await extractCapturedParcels(capturedIds);
    const responseData = axiosResponse.data;
    if (axiosResponse.status === 200) {
      setShowModal(false);
    }
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      isZK ? `zk-export-${Date.now()}.xlsx` : `parcel-export-${Date.now()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error making API call:", error);
  }
};
