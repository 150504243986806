import { FunctionComponent, useState } from "react";
import ChevronDownIcon from "../../../../../assets/svg/ChevronDownIcon";
import ChevronUpIcon from "../../../../../assets/svg/ChevronUpIcon";
import { CatastreData } from "../../../api/getParcelInfos";

interface FirstTabProps {
  parcelInfoData: CatastreData | undefined;
}

export const FirstTab: FunctionComponent<FirstTabProps> = ({
  parcelInfoData,
}) => {
  const parcelName = parcelInfoData ? parcelInfoData.number : "";
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUpisaneOsobeExpanded, setIsUpisaneOsobeExpanded] = useState(false);
  return (
    <div className="modal-content">
      <div className="modal-content-row">
        <p className="modal-item-text">Broj katastarske čestice:</p>
        <p className="modal-text">{parcelName}</p>
      </div>
      <div className="modal-content-row">
        <p className="modal-item-text">Katastarska općina:</p>
        <p className="modal-text">
          {parcelInfoData?.municipality}
          {parcelInfoData?.municipality}
        </p>
      </div>
      {parcelInfoData?.addresses.map((address) => (
        <div className="modal-content-row">
          <p className="modal-item-text">Adresa:</p>
          <p className="modal-text">
            {address.descriptiveAddress || address.settlementName}
          </p>
        </div>
      ))}
      <div className="modal-content-row">
        <p className="modal-item-text">Površina &#40;m2&#41;:</p>
        <p className="modal-text">{parcelInfoData?.realArea}</p>
      </div>
      <div className="accordion-content">
        <div className="accordion-content-row">
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className="accordion-header-container"
          >
            <p className="modal-item-text">Načini uporabe</p>
            {isExpanded ? (
              <ChevronUpIcon height={21} width={21} />
            ) : (
              <ChevronDownIcon height={21} width={21} />
            )}
          </div>
          {isExpanded ? (
            <div className="accordion-content-container">
              <table className="table-container">
                <thead>
                  <tr>
                    <th>
                      <p className="table-heading-text">Vrsta uporabe</p>
                    </th>
                    <th>
                      <p className="table-heading-text">Broj PL-a</p>
                    </th>
                    <th>
                      <p className="table-heading-text">
                        Površina &#40;m2&#41;
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {parcelInfoData?.buildings.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="table-body-data">
                        <p className="table-data-text">{item.typeName}</p>
                      </td>
                      <td className="table-body-data">
                        <p className="table-data-text">
                          {item.titleDeedNumber}
                        </p>
                      </td>
                      <td className="table-body-data">
                        <p className="table-data-text">{item.area}</p>
                      </td>
                    </tr>
                  ))}
                  {parcelInfoData?.landUses.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="table-body-data">
                        <p className="table-data-text">{item.typeName}</p>
                      </td>
                      <td className="table-body-data">
                        <p className="table-data-text">
                          {item.titleDeedNumber}
                        </p>
                      </td>
                      <td className="table-body-data">
                        <p className="table-data-text">{item.area}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      <div className="accordion-content">
        <div className="accordion-content-row">
          <div
            onClick={() => setIsUpisaneOsobeExpanded(!isUpisaneOsobeExpanded)}
            className="accordion-header-container"
          >
            <p className="modal-item-text">Upisane osobe</p>
            {isUpisaneOsobeExpanded ? (
              <ChevronUpIcon height={21} width={21} />
            ) : (
              <ChevronDownIcon height={21} width={21} />
            )}
          </div>
          {isUpisaneOsobeExpanded ? (
            <div className="modal-content">
              {parcelInfoData?.titleDeeds && (
                <div className="owner-content">
                  {parcelInfoData.titleDeeds &&
                    parcelInfoData.titleDeeds.map((item) => (
                      <div>
                        {item.holders?.map((holder) => (
                          <div className="owner-content">
                            <div className="owner-content-row">
                              <p className="owner-content-item-text">
                                Posjedovni list
                              </p>
                              <p className="owner-content-text">
                                {item.number}
                              </p>
                            </div>
                            <div className="owner-content-row">
                              <p className="owner-content-item-text">
                                Ime i prezime/Naziv
                              </p>
                              <p className="owner-content-text">
                                {holder.name === null
                                  ? [holder.firstName, " ", holder.lastName]
                                  : holder.name}
                              </p>
                            </div>
                            <div className="owner-content-row">
                              <p className="owner-content-item-text">Udio</p>
                              <p className="owner-content-text">
                                {`${holder.holderShareNumerator}/${holder.holderShareDenominator}`}
                              </p>
                            </div>
                            <div className="owner-content-row">
                              <p className="owner-content-item-text">Adresa</p>
                              <p className="owner-content-text">
                                {holder.addresses.map((address: any) =>
                                  address.descriptiveAddress === null
                                    ? [
                                        address.streetName,
                                        " ",
                                        address.houseNumber,
                                        ", ",
                                        address.settlementName,
                                        ", ",
                                        address.country,
                                      ]
                                    : address.descriptiveAddress
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
