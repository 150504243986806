import { FunctionComponent } from "react";

import { posjedovnica } from "../../../../../table/utils";
import { Accordion } from "../Accordion";

interface ListAProps {
  setPosjedovnicaExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  aEntriesExpanded: boolean;
  listA: any;
}

export const ListA: FunctionComponent<ListAProps> = ({
  setPosjedovnicaExpanded,
  aEntriesExpanded,
  listA,
}) => {
  return (
    <div className="accordion-content">
      <Accordion
        setExpanded={setPosjedovnicaExpanded}
        expanded={aEntriesExpanded}
        title="Posjedovnice"
        listType="posjedovnica"
        data={listA}
        columnNames={posjedovnica}
      />
    </div>
  );
};
