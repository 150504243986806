import { FunctionComponent } from "react";
import { RenderTableData } from "./RenderTableData";
import { RenderTableHeader } from "./RenderTableHeader";
import styles from "./Table.module.css";

interface TableProps {
  columnNames?: string[];
  data?: any[];
  listType?: string;
}

export const Table: FunctionComponent<TableProps> = ({
  columnNames,
  data,
  listType,
}) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <RenderTableHeader columnNames={columnNames} />
        </thead>
        <tbody>
          <RenderTableData data={data} listType={listType} />
        </tbody>
      </table>
    </div>
  );
};
